// React imports
import { useEffect, useState, useRef, useCallback } from 'react';

// Third-party library imports
import { Tooltip } from 'react-tooltip';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { Projection } from 'ol/proj';
import { unByKey } from 'ol/Observable';
import { BiSave } from 'react-icons/bi';
import { VscWand } from 'react-icons/vsc';

import ButtonGroup from 'react-bootstrap/ButtonGroup';

// Contexts
import { useProject, ModelType } from '@contexts/Project.context';
import { useToast } from '@contexts/Toast.context';

// Hooks
import useNotify from '@components/notifictions/notify';
import useKeyboardShortcut from '@hooks/useKeyboardShortcut.hook';

// Utils
import {
	deleteLayerByCustomId,
	setLayersVisibilityByCustomIds,
	addMapLoadingSpinner,
	removeMapLoadingSpinner,
} from '@utils/map/helpers';
import {
	addDrawLayer,
	removeDrawLayer,
	drawEndInteraction,
	drawStartInteraction,
	deleteAnnotation,
	addExistingAnnotations,
	parseFeatureToAnnotation,
} from '@utils/map/singleImage.draw';
import { setDrawInteraction } from '@utils/map/singleImage.draw';

// Components
import {
	AnnotationToolBar,
	KeyboardShortcut,
} from '@components/map/sharedStyles.component';
import UtilityButton from './utilityButtons/UtilityButton';
import AnnotationDrawTools from './utilityButtons/AnnotationDrawTools';

// API
import { postTileData, initTraining, fetchUrl } from '@api';

const groupFeaturesByName = features => {
	const featuresByName = features.reduce((acc, feature) => {
		const name = feature.get('name');
		acc[name] = feature; // Assuming each name is unique
		return acc;
	}, {});

	return featuresByName;
};

const customLayerId = 'annotatePhotosLayer';

export default function AnnotateSingleImages({ layersToShow }) {
	const {
		mapObject,
		project,
		pickedTask,
		singleImageFeatures,
		modelType,
		annotationDrawTool,
		annotationMode,
		annotationSidebarData,
		toolBarVisible,
		confirmModalContent,
		colorOptions,
		dispatch,
	} = useProject();

	const { gridFeatures: imagesInAreaOfInterest } = annotationMode;
	const groupedFeatures = groupFeaturesByName(singleImageFeatures);

	const { checkForJobs } = useNotify();
	const { addToast } = useToast();

	// States to show loading spinner and disable tools
	const [loading, setLoading] = useState(false);
	const [disableTools, setDisableTools] = useState(false);

	// State to keep track of the active image
	const [activeImage, setActiveImage] = useState({
		index: 0,
		feature: imagesInAreaOfInterest[0],
		extent: null,
	});

	// Layers added to the map
	const [imageLayer, setImageLayer] = useState(null);
	const [drawLayer, setDrawLayer] = useState(null);

	const drawType =
		modelType === ModelType.OBJECT_DETECTION ? 'Circle' : 'Polygon';

	// State to keep track of the drawing or erasing
	const [drawing, setDrawing] = useState(true);

	//Refs
	const isInitiated = useRef(false); // To prevent re-rendering
	const mapListenerKeys = useRef(null); // To keep track of the listeners added to the map and be able to remove them
	const activeClassId = useRef(annotationSidebarData?.activeClassId ?? 0);
	const activeTileName = useRef(
		imagesInAreaOfInterest[0]?.properties?.tile_name ?? null
	);

	// End annotation session and start training
	const endAnnotation = async () => {
		const confirmContinue = await saveTileData();

		if (confirmContinue) {
			initTraining(project?.uuid, pickedTask.model_uuid)
				.then(res => {
					console.log('init training', res);
					if (res?.data?.warning) {
						addToast({
							id: `training_warning-${new Date().getTime()}`,
							className: 'bg-warning',
							title: `Training model '${pickedTask.description}' started with warnings:`,
							message: res.data.warning,
							autohide: false,
						});
					} else if (res?.data?.error || res?.data?.detail) {
						addToast({
							id: `training_error-${new Date().getTime()}`,
							className: 'bg-danger',
							title: `Training model '${pickedTask.description}' failed:`,
							message:
								(res?.data?.error || res?.data?.detail) ??
								'Unknown error starting training.',
							autohide: false,
						});
					} else if (res?.data?.status === 'success') {
						addToast({
							id: `training_success-${new Date().getTime()}`,
							className: 'bg-success',
							title: `Training model '${pickedTask.description}' started successfully`,
							message:
								'The process will take some time to complete.',
							autohide: false,
						});
					}
				})
				.finally(() => {
					stopAnnotation();
					setTimeout(() => {
						checkForJobs();
					}, 5000);
				});
		}
	};

	const saveAndExit = async () => {
		const confirmContinue = await saveTileData();

		if (confirmContinue) {
			stopAnnotation();
		}
	};

	// Stop annotation session
	const stopAnnotation = () => {
		console.log('stopping annotation');

		if (mapObject) {
			// Remove the listeners
			for (let key of mapListenerKeys.current) {
				unByKey(key);
			}
			mapListenerKeys.current = null;

			// Remove the image layer
			deleteLayerByCustomId(mapObject, customLayerId);

			// Remove the draw layer
			removeDrawLayer(mapObject, drawLayer);
		}

		setActiveImage(null);
		setImageLayer(null);
		setDrawLayer(null);

		dispatch({
			type: 'setDialogue',
			payload: null,
		});
		dispatch({
			type: 'setToolBarVisible',
			payload: true,
		});
		dispatch({
			type: 'setAnnotationMode',
			payload: null,
		});

		const addToShow = ['singleImageLayer', 'mapTilesLayer'];
		setLayersVisibilityByCustomIds(
			mapObject,
			[...layersToShow, ...addToShow],
			true
		);
	};

	// Save the annotations in the current square
	const saveTileData = async () => {
		setLoading(true);

		const { source: drawSource } = drawLayer || {};

		if (!drawSource) {
			setLoading(false);
			return false;
		}

		const features = drawSource.getFeatures();

		const annotations = features.map(feature =>
			parseFeatureToAnnotation({
				feature,
				taskId: pickedTask.model_uuid,
				modelType,
				tile_name: activeTileName.current,
				classId: activeClassId.current,
				currentImageExtent: activeImage.extent,
			})
		);

		if (annotations.length === 0) {
			if (
				!window.confirm(
					"No new annotations were made in this image. It could affect the model if something's missed. Still good to go?"
				)
			) {
				// Cancel saving data to annotate more
				setLoading(false);
				return false;
			}
		}

		const currentTileName = activeTileName.current;

		console.log('saving annotations', annotations);

		const res = await postTileData(
			project.uuid,
			pickedTask.model_uuid,
			currentTileName,
			annotations
		)
			.then(() => {
				return true;
			})
			.catch(error => {
				console.error(error);
				return false;
			})
			.finally(() => {
				setLoading(false);
			});

		return res;
	};

	// change the image to annotate
	const onChangeImage = async (layer, index) => {
		if (index < 0 || index >= imagesInAreaOfInterest.length) return;

		dispatch({
			type: 'setDialogue',
			payload: {
				header: 'Loading Image',
				body: 'Load time depends on the image size and resolution. Please wait...',
				fullWidth: true,
			},
		});

		try {
			setDisableTools(true);

			const feature = imagesInAreaOfInterest[index];

			// Clear any existing annotations
			const { source: drawSource } = drawLayer || {};
			if (drawSource) {
				drawSource.clear();
			}

			layer.setSource(null);

			const {
				tile_name: image_name,
				image_height_px: height,
				image_width_px: width,
			} = feature.properties;
			const image = await fetchUrl(
				`filelink?key=${project.uuid}/images/${image_name}`
			);
			// const image = await fetchUrl(
			// 	`filelink?key=${project.uuid}/images/thumbnails/${image_name}`
			// ); // Keeping this for future reference and debugging

			activeTileName.current = image_name;

			if (!image?.url) {
				console.error(
					'Could not get images from s3. Trying the next image.'
				);
				onChangeImage(layer, index + 1);
			}

			// Get the view and the current center
			const view = mapObject.getView();
			const center = view.getCenter();

			// Calculate the new extent based on the center
			const imageExtent = [
				center[0] - width / 2,
				center[1] - height / 2,
				center[0] + width / 2,
				center[1] + height / 2,
			];

			setActiveImage({
				index,
				feature,
				extent: imageExtent,
			});

			const projection = new Projection({
				code: 'xkcd-image',
				units: 'pixels',
				extent: imageExtent,
			});
			const source = new Static({
				url: image?.url,
				imageExtent: imageExtent,
				projection: projection,
			});

			source.once('imageloadend', () => {
				dispatch({
					type: 'setDialogue',
					payload: {
						header: 'Annotating',
						body: 'To maintain the accuracy of annotations, please make sure to annotate all relevant objects within the image before clicking "Next Image".',
						fullWidth: true,
					},
				});
			});

			source.once('imageloaderror', () => {
				console.error(
					'Could not add image to layer. Trying the next image.'
				);
				onChangeImage(layer, index + 1);
			});

			layer.setSource(source);

			// Fit the view to the extent
			view.fit(imageExtent, {
				duration: 0,
			});
		} catch (e) {
			console.error('Could not get images from s3', e);
			return null;
		}
	};

	const addDrawingInteraction = ({ drawSetup, layer }) => {
		const { draw, translate, modify } = drawSetup;

		// Need to activate/deactivate the interactions to be able to draw on top of other features
		const activateInteractions = bool => {
			translate.setActive(bool);
			modify.setActive(bool);
		};

		// Add drawstart interaction
		draw?.on('drawstart', e => {
			drawStartInteraction({
				e,
				layer,
				draw,
				classId: activeClassId.current,
				activateInteractions,
			});
		});

		// Add drawend interaction
		draw?.on('drawend', e => {
			drawEndInteraction({
				e,
				classId: activeClassId.current,
				modelType,
				activateInteractions,
			});
		});
	};

	/**
	 * Add any posible existing annotations to the map
	 */
	const addExistingAnnotationsToMap = async () => {
		// Check if activeImage has an extent property, if not, return early
		if (!activeImage?.extent) return;

		// Retrieve existing annotations for the active image's tile name
		const existingAnnotations =
			groupedFeatures[activeImage.feature.properties.tile_name]?.get(
				'detections'
			)?.features ?? null;

		// If there are existing annotations, proceed to add them to the map
		if (existingAnnotations) {
			// Function to get the draw source, waiting if necessary
			const getDrawSource = async () => {
				const { source: drawSource } = drawLayer || {};
				// If the draw source is not available, wait for it to be available
				if (!drawSource) {
					await new Promise(resolve => {
						setTimeout(() => {
							resolve();
						}, 1000); // Wait for 1 second before checking again
					});
					return getDrawSource(); // Recursively call getDrawSource until drawSource is available
				}
				return drawSource; // Return the draw source once available
			};

			// Await the draw source to be available
			const drawSource = await getDrawSource();

			// Add existing annotations to the map using the draw source
			addExistingAnnotations({
				source: drawSource,
				annotations: existingAnnotations,
				imageExtent: activeImage.extent,
				modelType,
			});
		}
	};

	// Move to the next image
	const nextImage = async () => {
		if (loading) return;

		if (activeImage.index < imagesInAreaOfInterest.length - 1) {
			const confirmContinue = await saveTileData();
			if (confirmContinue) {
				onChangeImage(imageLayer, activeImage.index + 1);
			}
		} else if (activeImage.index === imagesInAreaOfInterest.length - 1) {
			endAnnotation();
		}
	};

	// Start drawing
	const startDraw = () => {
		setDrawing(true);
		const { draw, select, translate, modify, pointerMoveRef } = drawLayer;
		draw.setActive(true);
		translate.setActive(true);
		modify.setActive(true);
		select.setActive(false);
		mapObject.un('pointermove', pointerMoveRef);
	};

	// Start erasing
	const startErase = () => {
		setDrawing(false);
		const { draw, select, translate, modify, pointerMoveRef } = drawLayer;
		draw.setActive(false);
		translate.setActive(false);
		modify.setActive(false);

		select.setActive(true);
		mapObject.on('pointermove', pointerMoveRef);
	};

	// Creates a confirm modal to cancel the annotation session
	const cancel = () => {
		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: 'Cancel annotation session',
				message:
					'Are you sure you want to cancel the annotation mode and exit?',
				onConfirm: stopAnnotation,
				onCancel: () => {},
			},
		});
	};

	// Keyboard shortcuts
	useKeyboardShortcut(
		'Enter',
		!!annotationMode && confirmModalContent === null,
		nextImage
	);
	useKeyboardShortcut(
		'Escape',
		!toolBarVisible && confirmModalContent === null,
		() => cancel()
	);

	const initSingleImageLayer = useCallback(async () => {
		if (activeImage === null || isInitiated.current) return;

		isInitiated.current = true;
		console.log('Starting annotation mode');

		const layer = new ImageLayer({
			source: null,
			visible: true,
			zIndex: 20,
			name: 'Annotate photos layer',
			properties: {
				customLayerId: customLayerId,
			},
		});
		mapObject.addLayer(layer);
		setImageLayer(layer);

		// Create the draw layer and interaction
		const drawSetup = addDrawLayer({
			map: mapObject,
			drawType,
			drawTool: annotationDrawTool,
			colorOptions,
		});
		setDrawLayer(drawSetup);
		addDrawingInteraction({ drawSetup, layer });

		// Add select interaction
		drawSetup?.select?.on('select', e => {
			// Remove the selected feature
			const selected = e.selected?.[0];
			if (selected) {
				deleteAnnotation({
					feature: selected,
					source: drawSetup.source,
				});
			}
		});

		onChangeImage(layer, activeImage.index);

		setLayersVisibilityByCustomIds(
			mapObject,
			['singleImageLayer', 'mapTilesLayer'],
			false
		);

		// Add loading spinner
		const startLoadKey = mapObject.on('loadstart', function () {
			addMapLoadingSpinner(mapObject);
			setDisableTools(true);
		});
		// Remove loading spinner
		const endLoadKey = mapObject.on('loadend', function () {
			removeMapLoadingSpinner(mapObject);
			setDisableTools(false);
		});

		// Save the listener keys to remove them later
		mapListenerKeys.current = [startLoadKey, endLoadKey];
	}, [mapObject, imagesInAreaOfInterest, activeImage]);

	// Starts the right annotation type
	useEffect(() => {
		initSingleImageLayer();
	}, [initSingleImageLayer]);

	useEffect(() => {
		if (annotationSidebarData) {
			// Update the active class id ref
			activeClassId.current = annotationSidebarData?.activeClassId ?? 0;
		}
	}, [annotationSidebarData]);

	useEffect(() => {
		addExistingAnnotationsToMap();
	}, [activeImage]);

	useEffect(() => {
		const { draw, source } = drawLayer || {};
		if (draw) {
			const newDraw = setDrawInteraction({
				map: mapObject,
				draw,
				source: source,
				drawType: drawType,
				drawTool: annotationDrawTool,
			});

			const newDrawSetup = { ...drawLayer, draw: newDraw };

			setDrawLayer(newDrawSetup);
			addDrawingInteraction({
				drawSetup: newDrawSetup,
				layer: imageLayer,
			});
		}
	}, [annotationDrawTool]);

	return (
		<AnnotationToolBar aria-label="Annotation tools">
			<Tooltip
				id="annotate-tip"
				variant="light"
				render={({ content, activeAnchor }) => (
					<span>
						{content}{' '}
						<KeyboardShortcut>
							{activeAnchor?.getAttribute(
								'data-tooltip-keyboardshortcut'
							)}
						</KeyboardShortcut>
					</span>
				)}
			/>

			<div>
				<UtilityButton
					label="Cancel"
					tooltip={{
						id: 'annotate-tip',
						content: 'Cancel the annotation mode and exit',
						place: 'top',
					}}
					onClick={cancel}
					variant="danger"
					keyboardShortcutLabel="ESC"
				/>
				<UtilityButton
					label="Save and exit"
					tooltip={{
						id: 'annotate-tip',
						content:
							'Save your annotations and exit the annotation mode',
						place: 'top',
					}}
					onClick={saveAndExit}
					variant="dark"
					icon={() => <BiSave />}
				/>
			</div>

			<AnnotationDrawTools
				startDraw={startDraw}
				startErase={startErase}
				drawing={drawing}
				disabled={loading || disableTools}
			/>

			<div className="d-flex gap-3 align-items-center">
				<div>
					Annotating {activeImage?.index + 1} /{' '}
					{imagesInAreaOfInterest.length}
				</div>
				<ButtonGroup>
					<UtilityButton
						label="Start AI-training"
						tooltip={{
							id: 'annotate-tip',
							content:
								'End annotation session and start AI-training',
							place: 'top',
						}}
						onClick={() => {
							dispatch({
								type: 'setConfirmModalContent',
								payload: {
									title: 'End annotation session',
									message:
										'Are you sure you want end the annotation session? Your data will be saved and AI-training will start.',
									onConfirm: endAnnotation,
									onCancel: () => {},
								},
							});
						}}
						disabled={loading}
						variant="dark"
						icon={() => <VscWand />}
					/>

					{activeImage?.index < imagesInAreaOfInterest.length - 1 ? (
						<UtilityButton
							label="Next Image"
							tooltip={{
								id: 'annotate-tip',
								content:
									'Save the annotations in the current image and move to the next tile',
								place: 'top',
							}}
							disabled={loading || disableTools}
							loading={loading}
							onClick={nextImage}
							variant="success"
							keyboardShortcutLabel="↵"
						/>
					) : null}
				</ButtonGroup>
			</div>
		</AnnotationToolBar>
	);
}
