import { useCallback, useState, useEffect, useRef } from 'react';

import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Style, Text } from 'ol/style';
import { unByKey } from 'ol/Observable';

import { useProject, ProjectMode } from '@contexts/Project.context';
import { deleteLayerByCustomId, getLayerByCustomId } from '@utils/map/helpers';
import { createTooltip } from '@utils/map/tooltip.overlay';

import Checkbox from '../sidebars/sidebarElements/checkbox.component';

// Helper function to format confidence
const formatConfidence = confidence => {
	if (confidence == null) return '';
	return confidence == 1
		? 'Confidence: 100%'
		: `Confidence: ${(confidence * 100).toFixed(2)}%`;
};

// Helper function to format other properties
const formatProperty = (label, value, unit) => {
	return value != null ? `\n${label}: ${value.toFixed(2)}${unit}` : '';
};

/**
 * responsible for rendering the point Info layer,
 */
const PointInfoLayer = () => {
	const { mapObject, features, toolBarVisible, projectMode } = useProject();

	const isOrthoMode = projectMode === ProjectMode.ORTHOPHOTO;

	const layerId = 'pointInfoLayer';
	const minZoom = 22;

	const adding = useRef(false);
	const [pointInfoLayer, setPointInfoLayer] = useState(null);

	const tooltipRef = useRef(null);
	const tooltip = useRef(null);
	const [tooltipContent, setTooltipContent] = useState(null);
	const [interactionKeys, setInteractionKeys] = useState(null);

	const [disabled, setDisabled] = useState(true);

	const removePointInfoInteractions = () => {
		if (interactionKeys) {
			console.log('removing point info interactions', interactionKeys);
			interactionKeys.forEach(unByKey);
			setInteractionKeys(null);
		}

		if (tooltip.current) {
			mapObject?.removeOverlay(tooltip.current);
			tooltip.current = null;
		}
	};

	const addPointInfoInteractions = () => {
		if (interactionKeys) {
			removePointInfoInteractions();
		}

		const keys = [];
		if (isOrthoMode) {
			if (!tooltip.current) {
				tooltip.current = createTooltip({
					mapRef: mapObject,
					tooltipRef: tooltipRef.current,
					id: 'point-info-tooltip',
				});
			}

			const poninterMove = mapObject.on('pointermove', e => {
				const _tooltip = tooltip.current;
				if (e.dragging || !toolBarVisible || !_tooltip) return;

				if (mapObject.getView().getZoom() > minZoom) {
					_tooltip.setPosition(null);
					return;
				}

				const featuresAtPixel = mapObject.getFeaturesAtPixel(e.pixel);
				const pointArray = featuresAtPixel?.filter(feature => {
					if (feature.get('type') === 'detectionPoint')
						return feature;
				});
				if (pointArray.length < 1) {
					_tooltip.setPosition(null);
					return;
				}

				_tooltip.setPosition(
					pointArray[0]?.get('geometry')?.flatCoordinates
				);

				const featureData = pointArray[0]?.get('data');

				const pointConfidence = formatConfidence(
					featureData?.confidence
				);
				const pointHeight = formatProperty(
					'Height',
					featureData?.height,
					'm'
				);
				const pointArea = formatProperty(
					'Area',
					featureData?.area,
					'm²'
				);
				const pointDiameter = featureData?.radius
					? formatProperty('Diameter', featureData.radius * 2, 'm')
					: '';

				const renderListItem = content => {
					return content ? (
						<li className="list-group-item">{content}</li>
					) : null;
				};

				setTooltipContent(
					<ul className="m-0 p-0 small">
						{renderListItem(pointConfidence)}
						{renderListItem(pointHeight)}
						{renderListItem(pointArea)}
						{renderListItem(pointDiameter)}
					</ul>
				);
			});

			keys.push(poninterMove);
		}

		const resolutionChange = mapObject
			.getView()
			.on('change:resolution', () => {
				if (mapObject.getView().getZoom() > minZoom) {
					setDisabled(false);
				} else {
					setDisabled(true);
				}
			});

		keys.push(resolutionChange);

		setInteractionKeys(keys);
	};

	const removePointInfoLayer = () => {
		if (mapObject) {
			const deleted = deleteLayerByCustomId(mapObject, layerId);
			if (deleted) {
				console.log('removed point info layer');
			}
		}

		setPointInfoLayer(null);
	};

	const updatePointInfoLayer = useCallback(() => {
		// if the map object exists create and add the layer
		if (mapObject && !adding.current) {
			adding.current = true;

			let layer = null;

			const existingLayer = getLayerByCustomId(mapObject, layerId);

			if (features?.length) {
				const pointInfoLayerSource = new VectorSource({
					features: features.map(feature => {
						return new Feature({
							geometry: new Point(feature.geometry.coordinates),
							type: 'detectionPointInfo',
							data: feature.properties,
						});
					}),
				});

				if (existingLayer) {
					console.log(
						`Point Info Layer already exists. Adding it to state.`
					);
					layer = existingLayer;
					// Update source
					layer.setSource(pointInfoLayerSource);
				} else {
					layer = new VectorLayer({
						zIndex: 12,
						minZoom,
						source: pointInfoLayerSource,
						name: 'Object details',
						properties: {
							customLayerId: layerId,
						},
						visible: isOrthoMode,
						style: textStyle,
					});

					// add the layer to the map
					mapObject.addLayer(layer);
					console.log('added Point Info Layer');
				}
			} else if (existingLayer) {
				// No data related to the layer and it is not needed anymore. Delete it.
				deleteLayerByCustomId(mapObject, layerId);
				removePointInfoInteractions();
			}

			setPointInfoLayer(layer);
			adding.current = false;
		}
	}, [features, tooltipRef]);

	useEffect(() => {
		updatePointInfoLayer();

		return () => {
			removePointInfoLayer();
			removePointInfoInteractions();
		};
	}, [updatePointInfoLayer]);

	useEffect(() => {
		if (!toolBarVisible && interactionKeys) {
			removePointInfoInteractions();
		} else if (toolBarVisible && pointInfoLayer) {
			addPointInfoInteractions();
		}
	}, [toolBarVisible]);

	useEffect(() => {
		if (!tooltipRef.current || tooltip.current) return;

		addPointInfoInteractions();
	}, [tooltipRef, pointInfoLayer]);

	if (!pointInfoLayer) return null;

	return (
		<div id="pointInfoLayer">
			<Checkbox
				label={pointInfoLayer.get('name')}
				layer={pointInfoLayer}
				defaultState={pointInfoLayer.getVisible()}
				handleCheck={() => {
					pointInfoLayer.setVisible(true);
				}}
				handleUncheck={() => {
					pointInfoLayer.setVisible(false);
				}}
				canEdit={false}
				disabled={disabled}
			/>
			<div className="ol-tooltip" ref={tooltipRef}>
				{tooltipContent && tooltipContent}
			</div>
		</div>
	);
};

export default PointInfoLayer;

const textStyle = feature => {
	const featureData = feature.get('data');

	const pointConfidence = formatConfidence(featureData?.confidence);
	const pointHeight = formatProperty('Height', featureData?.height, 'm');
	const pointArea = formatProperty('Area', featureData?.area, 'm²');
	const pointDiameter = featureData?.radius
		? formatProperty('Diameter', featureData.radius * 2, 'm')
		: '';

	const pointInfo = `${pointConfidence}${pointHeight}${pointArea}${pointDiameter}`;

	return new Style({
		text: new Text({
			text: pointInfo,
			font: '1rem Montserrat',
			fill: new Fill({
				color: '#fff',
			}),
			backgroundFill: new Fill({
				color: 'rgba(0, 0, 0, 0.6)',
			}),
			offsetX: 16, // Adjust this value to simulate horizontal padding
			textAlign: 'left',
			padding: [8, 8, 8, 8], // Padding for the text background
		}),
	});
};
