import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Range, getTrackBackground } from 'react-range';
import { debounce } from 'lodash';

import Input from '@components/form/Input';

const RangeWrapper = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 30px 8px 0;
`;

const RangeLabels = styled.div`
	display: flex;
	justify-content: space-between;
`;

const InputWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 3rem;
`;

const Label = styled.p`
	margin-bottom: 2rem;
`;

const RangeSlider = ({
	label,
	step,
	analasysRangeLimit,
	boxData,
	activeFilterTemplate,
	onChange,
}) => {
	const { max: analysisMax } = analasysRangeLimit;
	const { min: boxMin, max: boxMax } = boxData;

	const values = [boxMin, boxMax];

	const [inputMinValue, setInputMinValue] = useState(boxMin);
	const [inputMaxValue, setInputMaxValue] = useState(boxMax);

	const debounceSetInputValues = debounce((min, max) => {
		setInputMinValue(min);
		setInputMaxValue(max);
	}, 300);

	const getRangeMax = () => {
		// Calculate the highest value among inputMaxValue, boxMax, and analysisMax
		return Math.max(inputMaxValue, boxMax, analysisMax);
	};
	const rangeMin = 0;
	const rangeMax = getRangeMax();

	useEffect(() => {
		// Destructure min and max values from activeFilterTemplate if it exists
		const { min: inputMin, max: inputMax } = activeFilterTemplate || {
			min: inputMinValue,
			max: inputMaxValue,
		};

		// Determine the values to be set
		const minValue = activeFilterTemplate ? inputMin : boxMin;
		const maxValue = activeFilterTemplate ? inputMax : boxMax;

		// Call the debounced function to set the input values
		debounceSetInputValues(minValue, maxValue);
	}, [activeFilterTemplate]);

	const handleValueChange = (event, isMinValue) => {
		const value = parseFloat(event.target.value);

		// Check if the value is not a number
		if (isNaN(value)) {
			isMinValue ? setInputMinValue('') : setInputMaxValue('');
			return;
		}

		// Set the input value
		isMinValue ? setInputMinValue(value) : setInputMaxValue(value);

		// Ensure the new value is within the valid range
		if (isMinValue && value >= values[1]) {
			return;
		} else if (!isMinValue && value <= values[0]) {
			return;
		}

		// Trigger the onChange callback with the new values
		const newValues = isMinValue ? [value, values[1]] : [values[0], value];
		onChange(newValues);
	};

	const onChangeMinValue = event => handleValueChange(event, true);
	const onChangeMaxValue = event => handleValueChange(event, false);

	return (
		<div>
			<Label>
				<span>{label}</span>
			</Label>

			<RangeWrapper>
				<Range
					values={values}
					step={step}
					min={rangeMin}
					max={rangeMax}
					onChange={val => {
						onChange(val);
					}}
					onFinalChange={val => {
						onChange(val);
						setInputMinValue(val[0]);
						setInputMaxValue(val[1]);
					}}
					renderTrack={({ props, children }) => (
						<div
							onMouseDown={props.onMouseDown}
							onTouchStart={props.onTouchStart}
							style={{
								...props.style,
								height: '36px',
								display: 'flex',
								width: '100%',
							}}>
							<div
								ref={props.ref}
								style={{
									height: '5px',
									width: '100%',
									borderRadius: '4px',
									background: getTrackBackground({
										values,
										colors: ['#000', '#5ab188', '#000'],
										min: rangeMin,
										max: rangeMax,
									}),
									alignSelf: 'center',
								}}>
								{children}
							</div>
						</div>
					)}
					renderThumb={({ index, props, isDragged }) => (
						<div
							{...props}
							key={props.key}
							style={{
								...props.style,
								height: '16px',
								width: '16px',
								borderRadius: '50%',
								backgroundColor: isDragged
									? '#105937'
									: '#198754',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								boxShadow: '0px 2px 6px #1c1c1c',
							}}>
							<div
								style={{
									position: 'absolute',
									top: '-38px',
									color: '#fff',
									fontSize: '14px',
									padding: '4px',
									borderRadius: '4px',
									backgroundColor: '#000000',
								}}>
								{values[index].toFixed(1)}
							</div>
						</div>
					)}
				/>
			</RangeWrapper>

			<RangeLabels className="text-muted small">
				<span>{rangeMin}</span>
				<span>{rangeMax}</span>
			</RangeLabels>

			<InputWrapper className="mt-1 mb-2">
				<Input
					type="number"
					label="Min"
					min={rangeMin}
					max={rangeMax}
					value={inputMinValue}
					step={step}
					onChange={onChangeMinValue}
					isInvalid={
						isNaN(inputMinValue) || inputMinValue > values[1]
					}
					minwidth={'120px'}
					noWrap
				/>
				<Input
					type="number"
					label="Max"
					min={rangeMin}
					max={Infinity}
					value={inputMaxValue}
					step={step}
					onChange={onChangeMaxValue}
					isInvalid={
						isNaN(inputMaxValue) || inputMaxValue < values[0]
					}
					minwidth={'120px'}
					noWrap
				/>
			</InputWrapper>

			{inputMaxValue > analysisMax && (
				<p className="small text-warning text-end">
					Model results max value is {analysisMax}
				</p>
			)}
		</div>
	);
};

export default RangeSlider;
